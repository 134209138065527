import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../assets/png/logoRojo.png';
import PlayStore from '../../assets/png/google-play.png';
import {ReactComponent as Facebook} from "../../assets/svg/facebook.svg";
import {ReactComponent as Instagram} from "../../assets/svg/instagram.svg";
/* import {ReactComponent as Twitter} from "../../assets/svg/twitter.svg";
import {ReactComponent as Youtube} from "../../assets/svg/youtube.svg";
import {ReactComponent as Tiktok} from "../../assets/svg/tiktok.svg"; */
/* import {ReactComponent as AppStore} from "../../assets/svg/Download_on_the_App_Store_Badge_ESMX_RGB_blk_100217.svg"; */
import './Footer.scss';

export default function Footer() {
    return (
        <div className='footer_container_option' id='footer'>
         <Container>
            <Row>
                <Col  md={3} sm={12} xl={3} className='logo'>
                    <div className="logo_descripcion">
                     <img src={Logo}></img>
                    <p>Atención y Solución de Faltas Administrativas o Cívicas</p>   
                    </div>
                    <div className="descripcion">
                        <p>ASFAC es una plataforma que opera a través de una aplicación móvil y brinda apoyo legal a los ciudadanos en caso de haber cometido faltas administrativas o cívicas. Mediante una suscripción mensual, los usuarios pueden acceder a los servicios de ASFAC para resolver de manera legal y eficiente cualquier falta que hayan cometido.</p>
                    </div>
                </Col>
                <Col  md={6} sm={12} xl={6} className='servicios_container'>
                    <h5>NUESTROS SERVICIOS</h5>
                    <p>Con ASFAC, estás protegido en caso de una amplia gama de faltas administrativas.</p>
                    <Row>
                    <Col  md={6} sm={12} xl={6} className='servicios_lista'>
                    <li>Realizar actos sexuales y/o eróticos en la vía pública o a bordo de un vehículo</li>
                    <li>Realizar necesidades fisiológicas en la vía pública o lugares públicos</li>
                    <li>Causar escándalo en lugares públicos y/o alteración del orden</li>
                    <li>Ingerir bebidas alcohólicas en la vía pública o a bordo de vehículos automotores</li>
                    <li>Romper banquetas y/o pavimento</li>
                    </Col> 
                    <Col  md={6} sm={12} xl={6} className='servicios_lista'>
                    <li>Realizar trabajos de albañilería en la vía pública</li>
                    <li>Publicar mediante vallas móviles, perifoneo, volanteo, degustación, encuestas y similares</li>
                    {/* <li>Infracciones de tránsito</li>
                    <li>Parquímetro</li> */}
                    <li>Alcoholímetro</li>
                    </Col>  
                    </Row>
                </Col>
                <Col  md={3} sm={12} xl={3} className='contacto'>
                <h5>CONTACTO ASFAC</h5>
                <p>Siguenos</p>
                <div className="social_media_container">
                    <a href="https://www.facebook.com/asfacmexico"><Facebook className='face' target="_blank"/></a>
                    <a href="https://www.instagram.com/asfac_mexico"><Instagram className='insta' target="_blank"/></a>
                    {/* <a href="#"><Twitter/></a>
                    <a href="#"><Youtube/></a>
                    <a href="#"><Tiktok/></a> */}
                </div>        
                <p>Descarga la app</p>
                <div className="container_tiendas">
                  <a href="https://play.google.com/store/apps/details?id=com.asfac.app&pcampaignid=web_share"><img src={PlayStore} alt="" /></a>
                  {/* <a href="#"><AppStore/></a>   */}
                    
                </div>
                </Col>
                <Col  md={12} sm={12} xl={12} className='asfac_copi'>
                <p>&copy; {(new Date().getFullYear())} Atención y Solución de Faltas Administrativas o Cívicas </p>   
                </Col>
            </Row>
         </Container>
        </div>
    )
}
