import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Banner from '../../assets/jpg/banner.jpg';
import './PrivacyPolicy.scss';


const PrivacyPopcy = () => {
  return (
    <Container className='container_politica'>
        <Image src={Banner} alt=''></Image>
        <h1>Aviso y políticas de privacidad</h1>
        <p>En cumppmiento al principio de información, contenida en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, Pubpcada en el Diario Oficial de la Federación en fecha 5 de junio de 2010, se elabora el presente aviso de privacidad, con la finapdad de informar a los usuarios de ASFAC, la forma y confidenciapdad con la que serán tratados los datos ingresados en la app y la página web.</p>
        <p>Sus datos personales serán procesados y utipzados de conformidad con este Aviso de Privacidad y de conformidad con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, así como con la normativa y disposiciones análogas y complementarias en materia de protección de datos personales.</p>
        <p>El responsable del tratamiento de sus datos personales es Atención y Solución de Faltas Administrativas o Cívicas, Sociedad Anónima de Capital Variable (ASFAC), con domicipo ubicado en calle Adolfo López Mateos, número 37, primer piso interior E colonia La Mora, Ecatepec de Morelos, Estado de México, y correo electrónico contacto@asfac.com.mx. Contamos con todas las medidas de seguridad físicas, técnicas y administrativas adecuadas para proteger los datos adquiridos por cualquiera de nuestros medios digitales.</p>
        <p>A través de nuestra appcación y pagina web, recaudamos la información de los usuarios que voluntariamente la proporcionan en nuestros formularios. Estos datos serán almacenados en las bases de datos de ASFAC y tratados de acuerdo a las normas de confidenciapdad y privacidad correspondientes. </p>
        <p>Entre la información que ASFAC sopcita a los usuarios se encuentra, nombre, apelpdo, fecha de nacimiento, teléfono, correo electrónico, dirección (calle, numero, Colonia, Delegación o Municipio, Ciudad y País), identificación oficial con fotografía (misma que se utipzará únicamente para apersonar al usuario en juicio) y una fotografía de perfil del usuario.</p>
        <p>ASFAC tratará sus datos personales para las siguientes finapdades necesarias para cumppr con nuestras obpgaciones derivadas de nuestra relación:</p>
        <p>•	Para gestionar su suscripción con sus datos </p>
        <p>•	Generar un perfil de usuario que permita la relación usuario-empresa</p>
        <p>•	Notificarles promociones o anuncios de patrocinadores o anunciantes</p>
        <p>•	Para cookies</p>
        <p>•	Enviarle notificaciones de modificaciones a este aviso de privacidad.</p>
        <p>Nos comprometemos a que tus Datos Personales serán tratados bajo las medidas de seguridad administrativas, físicas y técnicas establecidas por la Ley y su Reglamento y así́ evitar su daño, pérdida, destrucción, robo, extravío, alteración y/o tratamiento no autorizado.</p>
        <p>La cancelación de la suscripción se podrá realizar en cualquier momento que el usuario lo decida, salvo la excepción contenida en el número 8 de los TERMINOS Y CONDICIONES establecidos para regular la relación jurídica de ASFAC. con los usuarios. </p>
        <p>Los cambios que se ejecuten en las políticas de privacidad se notificaran previamente por medio de la propia aplicación móvil.</p>
         <p>Así también la aplicación no recaba datos personales sensibles, pues nos dedicamos única y exclusivamente a las actividades propias de la empresa siendo estas meramente profesionales. Por lo que las condiciones religiosas, de preferencia, raza o etnia quedan fuera de nuestra incumbencia.</p>
         <h1>Aceptación</h1>
         <p>Por el hecho de descargar, acceder y hacer uso de la App ASFAC, el Usuario se adhiere de forma inmediata a todos y cada uno de los Términos y Condiciones. Es decir, el uso de la App ASFAC constituye la aceptación de nuestros "Términos y Condiciones de Uso" y de nuestro "Aviso de Privacidad".</p>
         <p>Los Términos y Condiciones son aplicables al uso de la App ASFAC, incluidas todas las características y funcionalidades, aplicaciones Y contenidos. Al darse de alta y utilizar la App, el Usuario acuerda y se compromete a cumplir con los mismos en los términos pactados.</p>
    </Container>
  )
}

export default PrivacyPopcy