import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from "react-scroll"; 
import './Banner.scss';

const Banner = () => {
  return (
    <div className="banner" id='home'>
    <div className="police-lights">
        <div className="gradient-overlay"></div>
      </div>
      <Container>
        <div className="content">
          <h1>Bienvenido a ASFAC</h1>
          <p>La aplicación para resolver tus faltas administrativas de manera rápida y sencilla.</p>
          <Link className="cta-button"
            activeClass="active"
            to="descarga"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >Descargar la aplicación</Link>
         {/*  <Button variant="primary" size="lg" className="cta-button">Descargar la aplicación</Button> */}
        </div>
      </Container>
    </div>   
  )
}

export default Banner;
