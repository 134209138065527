import { useState } from "react";
import { useParams, useHistory } from 'react-router-dom'; 
import { Container, Image, Form , Button, Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import GuiaLogo from "../../assets/png/logoRojo.png";
import './ResetPassword.scss';
import { resetPasswordLocate  } from '../../api/reset';
/* import { fetchSinToken } from "../../../helpers/fetch"; */
import {toast} from 'react-toastify';
 
const ResetPassword = () => {
  const { token } = useParams();
  const history = useHistory(); 
  const [loading, setLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const formik = useFormik({
       initialValues: initialValues(),
       validationSchema: Yup.object().shape(validationSchema()),
      onSubmit:async (formData)=> {
        setLoading(true)
        
       const res = await  resetPasswordLocate(token, formData);

       console.log(res)
       console.log(res.ok)
 
       if(!res.ok){
       toast.error(res.msg)
    }else{
       setIsVerify(true)
    }
    setLoading(false)
    resetInputPassword();
    resetInputPassword2();
      }
     })
    

     const goHome = () => {
      history.replace('/'); 
  }
 
     const resetInputPassword = () =>{
      
          formik.values.password=""
  
  }
  const resetInputPassword2 = () =>{
      
    formik.values.password=""

}

console.log(isVerify)

  return (
    <Container className='container_reset'>
        <div className="reset">
            <div className="container_image">
                <Image src={GuiaLogo} alt='' />
                {
                  isVerify === false ?
                  <>
                  <p className='title'>Restablecer contraseña</p>
                <div className="container_form">
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Escribe una nueva contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                onChange={formik.handleChange}
                                isInvalid={formik.errors.password && true}
                                isValid={!formik.errors.password }
                            />
                             <Form.Control.Feedback type="invalid">
                             {formik.errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Confirmar contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Repeat Password"
                                name="repeatPassword"
                                onChange={formik.handleChange}
                                isInvalid={formik.errors.repeatPassword && true}
                                isValid={!formik.errors.repeatPassword }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.repeatPassword}
                             </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="danger" type="submit" disabled={loading}>
                        {
                          loading === false ? '' :
                          <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        }
                       Enviar
                      </Button>  
                    </Form>
                </div>
                  </> : 
                  <div className="exito">
                   <p className="exito_texto">¡Contraseña Cambiada!</p>
                  <p className="exito_sub">Tu contraseña ha sido cambiada con éxito. Ahora puedes acceder a tu cuenta con tu nueva contraseña.</p> 
                  <Button variant="danger" onClick={goHome}>Cerrar ventana</Button>
                  </div>
                }
                
            </div>
        </div>
    </Container>
);
}

function initialValues(){
  return {
    password:"",
    repeatPassword:"",
  }
}
function validationSchema(){
  return{
    password: Yup.string().required('La contraseña es obligatoria',true).oneOf([Yup.ref('repeatPassword')], 'Las contraseñas no son iguales').min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
    repeatPassword: Yup.string().required('La contraseña es obligatoria',true).oneOf([Yup.ref('password')], 'Las contraseñas no son iguales').min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
  };
}

export default ResetPassword