import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Explicacion.scss';
import Step1 from '../../assets/jpg/step1.jpg';
import Step2 from '../../assets/jpg/step2.jpg';
import Step3 from '../../assets/jpg/step3.jpg';
 
const Explicacion = () => {
  return (
    <div className="explicacion" id='funcion'>
      <Container>
        <h2>¿Cómo funciona?</h2>
        <Row className="steps">
          <Col md={4}>
            <div className="step">
              <h3>Solicita asistencia</h3>
              <img src={Step1} alt="Paso 1" />
              <p>Solicita una opción de asisitencia que necesites.</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="step">
              <h3>Gestor a la orden</h3>
              <img src={Step2} alt="Paso 2" />
              <p>Un gestor de ASFAC se encargará de atender tu caso y pagar tu multa.</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="step">
              <h3>Disfruta tu día</h3>
              <img src={Step3} alt="Paso 3" />
              <p>Agradece al gestor y continúa disfrutando de tu día.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Explicacion;
