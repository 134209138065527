import React from 'react';
import './Caracteristicas.scss';
import { Container } from 'react-bootstrap';

const Caracteristicas = () => {
  return (
    <div  className='container_caracteristicas'>
   <Container>
      <div className="caracteristicas" id='falta'>
        <h2>¿Qué es una falta administrativa?</h2>
        <p>
          Una falta administrativa es una infracción o incumplimiento de las normas establecidas por las autoridades
          competentes en materia administrativa. Estas faltas pueden incluir acciones como estacionarse en lugares
          prohibidos, exceso de velocidad, no respetar señales de tránsito, entre otras.
        </p>
      </div>
    </Container>   
    </div>
    
  );
};

export default Caracteristicas;
