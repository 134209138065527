import React from 'react';
import './DeleteAccount.scss';
import { useHistory } from 'react-router-dom';

const DeleteAccount = () => {
  const history = useHistory();

  const handleBackToHome = () => {
    history.push('/');
  };

  return (
    <div className="delete-account-container">
      <h2>Eliminar Cuenta de Forma Permanente</h2>
      <p>
        Para eliminar tu cuenta de forma permanente en Asfac, sigue los siguientes pasos:
      </p>
      <ul>
        <li>1. Concluye tu suscripción, si estás dado de alta.</li>
        <li>2. Oprime el botón <strong>"Eliminar mi cuenta"</strong> en la aplicación.</li>
        <li>3. Cierra sesión.</li>
      </ul>
      <p>
        Una vez que hayas completado estos pasos, no deberás iniciar sesión durante un periodo de <strong>3 meses</strong>. Si inicias sesión en este tiempo, la solicitud de eliminación será cancelada. 
      </p>
      <p>
        Si no inicias sesión dentro de los 3 meses, tu cuenta será eliminada de forma permanente. Recibirás un correo electrónico <strong>una semana antes</strong> de que esta acción sea irreversible.
      </p>
      <p>
        Si después de este periodo deseas volver a Asfac, tendrás que crear una nueva cuenta desde cero.
      </p>
      <button className="back-button" onClick={handleBackToHome}>
        Regresar a la Página Principal
      </button>
    </div>
  );
};

export default DeleteAccount;
