import React from 'react';
import './Principal.scss';
import Banner from '../Banner/Banner';
import Caracteristicas from '../Caracteristicas/Caracteristicas';
import Opciones from '../Opciones/Opciones';
import Explicacion from '../Explicacion/Explicacion';
import Download from '../Download/Download';

export default function Principal() {
  return (
    <div>
    <Banner/>
    <Caracteristicas/>
    <Opciones/>
    <Explicacion/>
    <Download/>
    </div>
  );
}
