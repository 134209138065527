import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './Opciones.scss';

const Opciones = () => {
    const faltas = [
        {
          title: 'Realizar actos sexuales y/o eróticos en la vía pública o a bordo de un vehículo',
          image: require('../../assets/png/carsex.png'),
        },
        {
          title: 'Realizar necesidades fisiológicas en la vía pública o lugares públicos',
          image: require('../../assets/png/aseo.png'),
        },
        {
          title: 'Causar escándalo en lugares públicos y/o alteración del orden',
          image: require('../../assets/png/bocina.png'),
        },
        {
          title: 'Ingerir bebidas alcohólicas en la vía pública o a bordo de vehículos automotores',
          image: require('../../assets/png/alcohol.png'),
        },
        {
            title: 'Romper banquetas y/o pavimento',
            image: require('../../assets/png/martillo.png'),
          },
        {
          title: 'Realizar trabajos de albañilería en la vía pública',
          image: require('../../assets/png/ladrillos.png'),
        },
        {
          title:
            'Publicar mediante vallas móviles, perifoneo, volanteo, degustación, encuestas y similares',
          image: require('../../assets/png/megafo.png'),
        },
       /*  {
          title: 'Infracciones de tránsito',
          image: require('../../assets/png/infraccion.png'),
        }, */
        {
          title: 'Alcoholímetro',
          image: require('../../assets/png/alcoholimetro.png'),
        },
        /* {
            title: 'Parquímetro',
            image: require('../../assets/png/parking.png'),
          }, */
      ];
    
      return (
        <div className="container_faltas_admin">
        <Container>
          <div className="faltas-administrativas">
            <h2>Te brindamos protección en situaciones como:</h2>
            <Row>
              {faltas.map((falta, index) => (
                <Col key={index} xs={12} sm={6} md={6} lg={3}>
                  <div className="falta-item">
                    <img src={falta.image} alt={falta.title} />
                    <p>{falta.title}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
        
      );
    };
    

export default Opciones