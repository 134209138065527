import React from 'react';
import './Download.scss';
import Dispositios from '../../assets/png/moviles.png';
import { Container, Row, Col } from 'react-bootstrap';

const Download = () => {
  return (
    <div className="download_container" id='descarga'>
    <Container  className="download">
      {/* <div className="parallax">
        <img src={parallaxImage} alt="Parallax" />
      </div> */}
      <Row className="content">
        <Col  md={6} sm={12} xl={6} className="info">
        <h2>¡Ya disponible en Play Store!</h2>
<p>Nos complace anunciar que nuestra aplicación ya está disponible para descarga en <span>Play Store</span>. Actualmente, estamos operando en <span>Ecatepec, Estado de México</span>, y pronto expandiremos nuestros servicios a más áreas. Descarga nuestra aplicación y disfruta de un acceso rápido y sencillo a todos nuestros servicios.</p>

        </Col>
        <Col  md={6} sm={12} xl={6} className='imagenes'>
    <img src={Dispositios} alt="" />
        </Col>
      </Row>
    </Container>  
    </div>
    
  );
}

export default Download;
