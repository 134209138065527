import React from 'react';
import './Error404.scss';


export default function Error404() {
    return (
        <div className='error_container'>
            <h1>No se encontro esta pagina</h1>
        </div>
    )
}
