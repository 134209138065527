import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from "react-scroll"; 
import Logo from '../../assets/png/logoRojo.png';
import {ReactComponent as Open} from "../../assets/svg/menu-hamburguesa.svg";
import './Navbar.scss';

export default function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [active, setActive] = useState("navigation");

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navToggle = () =>{
    active === 'navigation' ? setActive('navigation nav__active') : setActive('navigation')
  }

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <nav className="navbar">
        <Container>
          <div className="navbar-brand">
            <img src={Logo} alt="Menu" className="menu-icon" onClick={toggleMenu} />
          </div>
          <ul className={active}>
          <Link className="nav-item"
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >Home</Link>
            <Link className="nav-item"
            activeClass="active"
            to="falta"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >Falta administrativa</Link>
            <Link className="nav-item"
            activeClass="active"
            to="funcion"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >¿Cómo funciona?</Link>
            <Link className="nav-item"
            activeClass="active"
            to="descarga"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >Descarga</Link>
            <Link className="nav-item"
            activeClass="active"
            to="footer"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >Info</Link>
          </ul>
          <div className="sideBar" onClick={navToggle}>
            <Open/>
          </div>
        </Container>
      </nav>
    </header>
  );
}
